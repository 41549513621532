import { setUserJourneyStatus as setUserJourneyStatusAction } from '@redux/rfq/actionCreators';
import { changePassword as changePasswordAction } from '@redux/rfq/thunks';
import {
	PasswordSessionStorageKeyName,
	fetchTemporaryPassword,
} from '@redux/rfq/utils';
import { InlineAlert } from '@xo-union/tk-component-alerts';
import React, { VFC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PasswordModal, { parseEmail } from '../PasswordModal/PasswordModal';
import styles from './ExitEarlyAlert.scss';
import {
	ActionType,
	useTrackPostLeadInteraction,
} from './hooks/use-track-post-lead-interaction';
import {
	ACTION_CLICK_SET_PASSWORD,
	SOURCE_CONTENT_INLINE_MESSAGE,
} from './utils/constants';

import AnalyticsEvents from '../../../../constants/analytics';

const { SAVE_PASSWORD } = AnalyticsEvents;
export interface ExitEarlyAlertProps extends StateProps, DispatchProps {
	isStorefrontPage?: boolean;
}

interface StateProps {
	anonymousId: Redux.Settings['anonymousId'];
	changePasswordStatus: Redux.Rfq['changePasswordStatus'];
	creationSuccessful: Redux.Rfq['creationSuccessful'];
	email: string;
	userJourneyStatus: Redux.Rfq['userJourneyStatus'];
	userId?: string;
}

interface DispatchProps {
	changePassword: (newPassword: string, type?: string) => void;
	setJourneyStatus: (
		userJourneyStatus: 'init' | 'exit-early' | 'complete',
	) => void;
}

interface AnalyticsArgs {
	action: ActionType;
	sourceContent: string;
}

const ExitEarlyAlert: VFC<ExitEarlyAlertProps> = (props) => {
	const [isAlertShown, setIsAlertShown] = useState(false);
	const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
	const [password, setPassword] = useState('');

	useEffect(() => {
		// "sessionStorage is not available for opaque origins"
		// cannot initialize state with a sessionStorage value - but it can be done in useEffect
		setIsAlertShown(!!fetchTemporaryPassword());
	}, []);

	// TODO: use useSyncExternalStore - React 18
	const passwordListener = useCallback((event: StorageEvent) => {
		setIsAlertShown(!!event.newValue);
	}, []);

	const removePasswordListener = useCallback(() => {
		window.removeEventListener(
			`sessionStorage-${PasswordSessionStorageKeyName}`,
			passwordListener,
		);
	}, [passwordListener]);

	useEffect(() => {
		window.addEventListener(
			`sessionStorage-${PasswordSessionStorageKeyName}`,
			passwordListener,
		);
		return removePasswordListener;
	}, [passwordListener, removePasswordListener]);

	const trackPostLeadInteraction = useTrackPostLeadInteraction(
		props.userId,
		props.anonymousId,
	);

	const handleAnalytics = (analytics: AnalyticsArgs) => {
		trackPostLeadInteraction(analytics.action, analytics.sourceContent);
	};

	const handleSubmit = (analytics: AnalyticsArgs) => {
		props.changePassword(props.email, password);
		handleAnalytics(analytics);
	};

	useEffect(() => {
		if (props.changePasswordStatus.changeSuccessful === 'success') {
			props.setJourneyStatus('complete');
			setIsPasswordModalOpen(false);
			setIsAlertShown(false);
			removePasswordListener();
		}
	}, [
		props.changePasswordStatus.changeSuccessful,
		props.setJourneyStatus,
		removePasswordListener,
	]);

	if (!isAlertShown) {
		return null;
	}

	return (
		<>
			<InlineAlert
				className={
					props.isStorefrontPage
						? styles.storefrontContainer
						: styles.vendorSearchContainer
				}
				type="info"
			>
				Welcome to The Knot! To complete your account creation,{' '}
				{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
				<span
					className={styles.linkButton}
					role="button"
					onClick={() => {
						handleAnalytics({
							action: ACTION_CLICK_SET_PASSWORD,
							sourceContent: SOURCE_CONTENT_INLINE_MESSAGE,
						});
						setIsPasswordModalOpen(true);
					}}
				>
					set a password
				</span>
				.
			</InlineAlert>
			{isPasswordModalOpen && (
				<PasswordModal
					isModalOpen={isPasswordModalOpen}
					closeCallback={() => setIsPasswordModalOpen(false)}
					changePasswordStatus={props.changePasswordStatus}
					handleSubmit={handleSubmit}
					submitAnalytics={{
						action: SAVE_PASSWORD,
						sourceContent: SOURCE_CONTENT_INLINE_MESSAGE,
					}}
					password={password}
					updatePassword={setPassword}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	anonymousId: state.settings.anonymousId,
	creationSuccessful: state.rfq.creationSuccessful,
	userJourneyStatus: state.rfq.userJourneyStatus,
	changePasswordStatus: state.rfq.changePasswordStatus,
	email: parseEmail(state),
	userId: state.membership?.member?.id,
});

const mapDispatchToProps = {
	changePassword: changePasswordAction,
	setJourneyStatus: setUserJourneyStatusAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExitEarlyAlert);
